import { defineStore } from 'pinia';

export const useDataStore = defineStore({
    id: 'data',
    state: () => ({
        data: null
    }),
    getters: {
        getData: (state) => {
            return state.data;
        },
    },
    actions: {
        async setData(data) {
            this.data = data;
        },
    }
});

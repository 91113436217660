import { router } from '@/router';
import { useAlertStore } from "@/stores/alert.store";
import { usePrintStore } from "@/stores/print.store";
import { useStorage } from '@vueuse/core';
import { defineStore } from 'pinia';
import LoginService from "../services/LoginService";
import { useSocketStore } from '@/stores/socket.store'

export const useAuthStore = defineStore({
    id: 'auth',
    state: () => ({
        // initialize state from local storage to enable userInfo to stay logged in
        userInfo: useStorage('userInfo', {}),
        accessToken: useStorage('accessToken', ''),
        refreshToken: useStorage('refreshToken', ''),
        returnUrl: null,
        superAdminDashboardClicked: false,
    }),
    getters: {
        isLoggedIn: (state) => state.accessToken !== null && state.accessToken !== '' && state?.userInfo !== null && state?.userInfo?.user !=null,
        getAccessToken: (state) => {
            if (!state.isLoggedIn) return '';
            const token = state.accessToken
            return token === null ? '' : token;
        },
        getRefreshToken: (state) => {
            if (!state.isLoggedIn) return '';
            const refreshToken = state.refreshToken
            return refreshToken === null ? '' : refreshToken;
        },
        user: (state) => {
            if (!state.isLoggedIn) return null;
            const { user } = state.userInfo;
            return user;
        },
        branch: (state) => {
            if (!state.isLoggedIn) return null;
            const { branch } = state.userInfo;
            return branch;
        },
        org: (state) => {
            if (!state.isLoggedIn) return null;
            const { org } = state.userInfo;
            return org;
        },
        uniqueNo: (state) => {
            if (!state.isLoggedIn) return null;
            const { uniqueNo } = state.userInfo;
            return uniqueNo;
        },
        superUser: (state) => {
            if (!state.isLoggedIn) return null;
            const { user } = state.userInfo;
            return user.superUser;
        },
        transactionBlocked: (state) => {
            if (!state.isLoggedIn) return null;
            const { transactionBlocked } = state.userInfo;
            return transactionBlocked;
        },
        resourceAction: (state) => {
            if (!state.isLoggedIn) return null;
            const { resourceAction } = state.userInfo;
            return resourceAction;
        },
        package: (state) => {
            if (!state.isLoggedIn) return null;
            const {subscription} = state.userInfo;
            return subscription;
        },
        isOrgOwner: (state) => {
            if (!state.isLoggedIn) return false
            const { org, user } = state.userInfo;
            return org !== undefined && org !== null && org.ownerUserName != undefined && org.ownerUserName !== null && org.ownerUserName === user.username;
        },
        isSuperAdminDashboardClicked:(state) => {
            return !!state.superAdminDashboardClicked;
        }
    },
    actions: {
        async login(username, password) {
            // const auth = useAuthStore();
            const alert = useAlertStore();
            // const socketState = useSocket();
            try {
                if (username !== "" && password !== "") {
                    const loginService = new LoginService();
                    const userInfo = await loginService.userLogin({ username, password, "loginType": 'WEB' });
                    if (userInfo.success) {
                        // update pinia state
                        this.userInfo = await userInfo.result;
                        this.accessToken = userInfo.result.token;
                        this.refreshToken = userInfo.result.refreshToken;

                        // store userInfo details and jwt in local storage to keep userInfo logged in between page refreshes
                        setTimeout(() => {
                            alert.success("Successfully LoggedIn !!");
                            // clear data for bill print
                            const print = usePrintStore();
                            print.clearPrintStore();
                        }, 1000);
                        const socket = useSocketStore();
                        socket.connect();
                    }
                    // router.push(auth.org.id ? this.returnUrl || '/' : '/AddOrganizationDialog/' + auth.user.id);
                }
                return true;
            }
            catch (error) {
                if(error?.code===417){
                    return error.code;
                }
                return false;
            }
        },
        async refreshAccessToken(username, organizationCode, branchCode, newToken) {
            try {
                this.accessToken = newToken;
                const loginService = new LoginService();
                const userInfo = await loginService.refreshToken({ username, organizationCode, branchCode, "loginType": 'WEB' });

                if (userInfo.success) {
                    // update pinia state
                    this.userInfo = await userInfo.result;
                    this.accessToken = userInfo.result.token;
                }
                return true;
            } catch (error) {
                return false;
            }
        },

        logout() {
            this.userInfo = {};
            this.accessToken = '';
            this.refreshToken = '';
            this.returnUrl = '/';
            this.setSuperAdminDashboardClicked(false);

            const print = usePrintStore();
            print.clearPrintStore();

            const alert = useAlertStore();
            alert.error("Successfully Logout !!!");
            return router.push('/Login');
        },

        forceLogout() {
            this.userInfo = {};
            this.accessToken = '';
            this.refreshToken = '';
            this.returnUrl = '/';

            const print = usePrintStore();
            print.clearPrintStore();

            return router.push('/Login');
        },
        async saveSwitchUser(response) {
            const alert = useAlertStore();
            try {
                if (response !== "") {
                    if (response.success) {
                        // update pinia state
                        this.userInfo = await response.result;
                        this.accessToken = response.result.token;
                        this.refreshToken = response.result.refreshToken;

                        // clear data for bill print
                        const print = usePrintStore();
                        await print.clearPrintStore();

                        // store userInfo details and jwt in local storage to keep userInfo logged in between page refreshes
                        alert.success("Successfully switched branch");
                        // redirect to previous url or default to home page
                    }
                    await router.push(this.returnUrl || '/');
                }
            } catch (error) {
                alert.error(error.message);
            }
        },
        async setSuperAdminDashboardClicked(flag){
            this.superAdminDashboardClicked = flag;
        }
    }
});
import http from "../http-common";
import httpClient from "../http-common";

export default class OrganizationLogoService {
    getAll() {
        return http.get("/organizationLogo/all");
    }

    get(id) {
        return http.get(`/organizationLogo/${id}`);
    }

    getLazy(params) {
        return http.get("/organizationLogo/lazy", {params});
    }

    createOrganization(data) {
        return http.post("/organizationLogo/create", data);
    }

    create(data) {
        return http.post("/organizationLogo/", data);
    }


    uploadLogo(data, orgId) {
        return httpClient.post(`/organizationLogo/uploadLogo/${orgId}`, data, {
            headers: {
                "Content-Type": "multipart/form-data",
            }
        });
    }

    update(id, data) {
        return http.put(`/organizationLogo/${id}`, data);
    }

    delete(id) {
        return http.delete(`/organizationLogo/${id}`);
    }

    async getByOrgId(orgId) {
        return http.get(`/organizationLogo/organization/${orgId}`);
    }
}
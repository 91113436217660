import http from "../http-common";
import {useDeviceInfo} from "@/js/device.info";

export default class LoginService {
    async userLogin(data) {
        const di = useDeviceInfo()
        return http.post("/login/user-login", data, {headers: di.deviceInfo.value});
    }

    async refreshToken(data) {
        return http.post("/login/refresh-access-token", data);
    }

    selectBranch(data) {
        return http.post(`/login/select-branch`, data);
    }
    async validateNumberExpiry(data){
        return http.post(`/login/validateNumberExpiry`, data);
    }
}
import {defineStore} from 'pinia';

export const useAlertStore = defineStore({
    id: 'alert',
    state: () => ({
        alert: {severity: 'info', summary: 'Message', detail: 'This is message', life: 3000},
    }),
    getters: {
        hasAlert: (state) => state.alert !== null,
    },
    actions: {
        info(message) {
            this.alert = {severity: 'info', summary: message, detail: "", life: 3000};
        },
        success(message) {
            this.alert = {severity: 'success', summary: message, detail: "", life: 3000};
        },
        error(message) {
            this.alert = {severity: 'error', summary: message, detail: "", life: 3000};
        },
        warn(message) {
            this.alert = {severity: 'warn', summary: message, detail: "", life: 3000};
        },
        clear() {
            this.alert = null;
        },
        errorMessages(obj) {
            for (const key in obj) {
                if (typeof obj[key] === 'object' && obj[key] !== null) {
                    this.errorMessages(obj[key]);
                } else {
                    this.error(obj[key])
                }
            }
        }
    }
});
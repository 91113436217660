import { createApp, defineAsyncComponent } from 'vue';
import App from './App.vue';
import { router } from './router';
import { createPinia } from 'pinia'

import '@fortawesome/fontawesome-free/css/all.css'

// Core Components
import PrimeVue from 'primevue/config';

import ToastService from "primevue/toastservice";
import ConfirmationService from 'primevue/confirmationservice';
import Tooltip from "primevue/tooltip";
import BadgeDirective from 'primevue/badgedirective';
import Ripple from "primevue/ripple";

import '@/assets/styles.scss'

const NepaliCalendar = defineAsyncComponent(() => import('@/components/nepali-date-picker/NepaliCalendar.vue'));

const pinia = createPinia();
const app = createApp(App);
app.use(pinia);
app.use(router);

app.use(PrimeVue, {
    ripple: true,
    pt: {
        datatable: {
            column: {
                filterMenuButton: {
                    style: {display: 'none'}
                },
                filterInput: {
                    style: {minWidth: '3rem'}
                }
            }
        },
        autocomplete: {
            virtualScroller: {
                root: {
                    style: {'min-width': '23rem !important'},
                }
            }
        },
        dropdown: {
            wrapper: {
                style: {'min-width': '23rem !important'},
            }
        }
    },
});

app.directive('ripple', Ripple);

app.use(ToastService);
app.use(ConfirmationService);

app.directive('tooltip', Tooltip);
app.directive('badge', BadgeDirective);

app.component("NepaliCalendar", NepaliCalendar)

app.mount('#app');

import {ref} from "vue";
import {v4 as uuidv4} from "uuid";

const osMappings = {
    'Windows': 'Windows',
    'Mac': 'Mac OS',
    'Linux': 'Linux',
    'Ubuntu': 'Ubuntu',
    'Fedora': 'Fedora',
    'Debian': 'Debian',
    'Mint': 'Linux Mint',
    'Android': 'Android',
    'iOS': 'iOS',
};

// Extract browser information
const browserInfo = {
    Firefox: 'Mozilla Firefox',
    Chrome: 'Google Chrome',
    Safari: 'Apple Safari',
    Edge: 'Microsoft Edge',
};

const deviceInfo = ref({
    browser: '',
    browserVersion: '',
    operatingSystem: '',
    operatingSystemVersion: '',
    device: '',
    isMobile: false,
    androidVersion: '',
    iosVersion: '',
    deviceName: '',
    deviceId: ''
})

export function useDeviceInfo() {
    const userAgentString = navigator.userAgent;

    deviceInfo.value.browser = Object.keys(browserInfo).find(b => userAgentString.includes(b)) || 'Unknown';

    // Extract browser version
    const browserVersionMatch = userAgentString.match(/(?:Firefox|Chrome|Safari|Edge)\/([\d.]+)/);
    deviceInfo.value.browserVersion = browserVersionMatch ? browserVersionMatch[1] : 'Unknown';

    // Extract operating system information
    deviceInfo.value.operatingSystem = Object.keys(osMappings).find(os => userAgentString.includes(os)) || 'Unknown';

    // Extract operating system version
    const osVersionMatch = userAgentString.match(/(?:Windows NT|Mac OS X|Linux|Android|iOS) ([\d_.]+)/);
    deviceInfo.value.operatingSystemVersion = osVersionMatch ? osVersionMatch[1].replace(/_/g, '.') : 'Unknown';

    // Extract device information
    deviceInfo.value.device = userAgentString.includes('Mobile') ? 'Mobile Device' : 'Desktop';
    deviceInfo.value.isMobileDevice = deviceInfo.value.device === 'Mobile Device';

    // Extract Android version
    const androidVersionMatch = userAgentString.match(/Android\s([\d.]+)/);
    deviceInfo.value.androidVersion = androidVersionMatch ? androidVersionMatch[1] : 'Unknown';

    // Extract iOS version
    const iOSVersionMatch = userAgentString.match(/(?:iPhone\sOS|CPU\sOS)\s([\d_]+)/);
    deviceInfo.value.iOSVersion = iOSVersionMatch ? iOSVersionMatch[1].replace(/_/g, '.') : 'Unknown';

    // Extract device name (if available)
    const deviceNameMatch = userAgentString.match(/\(([^()]+)\)/);
    deviceInfo.value.deviceName = deviceNameMatch ? deviceNameMatch[1] : 'Unknown';

    let storedDeviceId = localStorage.getItem('deviceId');

    if (storedDeviceId) {
        deviceInfo.value.deviceId = storedDeviceId;
    } else {
        deviceInfo.value.deviceId = uuidv4();
        localStorage.setItem('deviceId', deviceInfo.value.deviceId);
    }

    return {deviceInfo};
}
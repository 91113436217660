import {useAuthStore} from "@/stores";

export const hasAcl = (resource, action) => {
    if (resource === undefined || action === undefined || resource === null || action === null) {
        return false;
    }
    const auth = useAuthStore();
    let resourceAction = auth.resourceAction;
    if (resourceAction === undefined || resourceAction === null || resourceAction.length === 0) {
        return false;
    }
    let res = resourceAction.find(x => x.resource === resource)
    if (res === undefined || res === null) {
        return false;
    }

    if (Array.isArray(action)) {
        const includes = res.actions.some(x => action.includes(x))
        return includes !== undefined && includes !== null ? includes : false
    }

    let act = res.actions.find(y => y === action);
    return !(act === undefined || act === null);

}

export default {
    install(app) {
        app.config.globalProperties.$checkACL = function (resource, action) {
            return hasAcl(resource, action);
        };
    },
};

